<template>
  <div class="md:p-10 p-4 ">
    <h1 class="md:text-4xl text-2xl font-bold text-center">Vente de bateaux et moteurs </h1>
    <div class="flex flex-wrap justify-evenly">
      <div class="img-container"><img src="../assets/img/boat&motor/image000000.webp" alt=""></div>
      <div class="img-container"><img src="../assets/img/boat&motor/touring_techpro2a.webp" alt=""></div>
      <div class="img-container"><img class="img3" src="../assets/img/boat&motor/image000001.webp" alt=""></div>
      <div class="img-container"><img class="img4" src="../assets/img/boat&motor/tiger740_techpro2a2.webp" alt=""></div>
      <div class="img-container"><img class="img5" src="../assets/img/boat&motor/tiger740techpro2a.webp" alt=""></div>
      <div class="img-container"><img class="img6" src="../assets/img/boat&motor/tiger_850_techpro2a.webp" alt=""></div>

    </div>
  </div>
</template>

<script>
export default {
  name: "ProductView",
  methods:{
    fullscreen(className){
      document.querySelector('.'+className).classList.add('fullscreen')
    }
  }
}
</script>

<style scoped>
.img-container{
  @apply m-6 p-4 grid content-center sm:w-[400px] sm:h-[300px] ;
}
img{
  width: 400px;
  object-fit: cover;
  @apply rounded hover:scale-105 duration-500 mx-auto border-4 border-blue-200 bg-blue-50;
}
.fullscreen{
  width: 90%;
  height: 90vh;
  position: absolute;
  z-index: 2000;
  top: 5vh;
  left: 5%;
}

@media screen and (max-width: 768px){

  img{
    width: 100%;
    max-width: 400px;
  }
  .img-container{
    width: 80%;

  }
}
</style>