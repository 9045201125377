import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProductView from "@/views/ProductView";
import RepairView from "@/views/RepairView";
import AmeliorationView from "@/views/AmeliorationView";
import WinterizeView from "@/views/WinterizeView";
import LocationView from "@/views/LocationView";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/bateaux-et-moteurs',
    name: 'productView',
    component: ProductView
  },
  {
    path: '/location',
    name: 'locationView',
    component: LocationView
  },
  {
    path: '/reparations',
    name: 'repairView',
    component: RepairView
  },
  {
    path: '/modifications-et-ameliorations',
    name: 'ameliorationView',
    component: AmeliorationView
  },

  {
    path: '/hivernage',
    name: 'winterizeView',
    component: WinterizeView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
