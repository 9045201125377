<template>
  <div class="">
    <div class="diag-bg h-1/2 absolute w-full -z-50 top-1/3"></div>
    <div class="md:p-10 p-4">
      <h1 class="md:text-4xl text-2xl font-bold text-center">Mecanique toutes marques, électricité, stratification</h1>
      <div class="lg:grid grid-cols-2 mt-10 lg:-mb-20 ">
        <div class="ltor">
          <img class="img1 rounded mx-auto z-30" src="../assets/img/reparation/propulseur_techpro2a.webp" alt="">
        </div>
        <div class="rtol">
            <p class="m-3 p-3 rounded-2xl border border-blue-200 bg-white text-gray-700 text-lg lg:block hidden text-justify">
              Étant équipé d'un outil diagnostic multimarques, nos 30 années d'expériences sont à votre service.
            </p>
        </div>
        <p class="p-3 rounded-2xl border border-blue-200 bg-white text-gray-700 h-min text-md md:text-lg lg:hidden block text-justify mt-5">
          Étant équipé d'un outil diagnostic multimarques, nos 30 années d'expériences sont à votre service.
        </p>

      </div>
      <img class="rtol-custom img2 rounded lg:ml-20 xl:ml-[20%] z-50 mt-5 lg:mt-0 z-30" src="../assets/img/reparation/reaparation_moteur.webp" alt="">

    </div>

  </div>

</template>

<script>
export default {
  name: "RepairView",
  data(){
    return{
      success: false
    }
  },
  methods:{

  }

}
</script>

<style scoped>

.diag-bg {
  background-image: linear-gradient(45deg, #0334b1, #3cffff);
  transform: skewY(-20deg);

}

img{
  @apply border-8 border-blue-50;

}
.img1{

  width: 700px;
  height: 400px;
  object-fit: cover;
}
.img2{

  width: 900px;
  object-fit: cover;
  height: 500px;
}

.rtol-custom {
  animation-duration: 1s;
  animation-name: r-to-l-custom;
}

@keyframes r-to-l-custom {
  from {
    margin-left: 110%;
  }
  to {
    margin-left: fill;
  }

}
</style>