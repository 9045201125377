<template>
  <div class="home ">
    <div class="zoom grid content-center justify-center">
      <h1 class="title xl:text-5xl lg:text-4xl text-3xl my-10 animate-LtoR transform duration-400
        duration-700 font-[CustomFont] text-white text-center w-full mt-12 p-8">
        TECH'PRO2A, LE TECHNICIEN DE VOTRE BATEAU
      </h1>
      <img class="main-img -z-10" src="../assets/img/port_bonif.jpg" alt="logo">
    </div>

    <div class="main p-8 pt-0 bg-white space-y-10 grid content-end relative">
      <div class="flex flex-wrap justify-center xl:mt-0 mt-5 w-full md:w-5/6 mx-auto">
        <router-link :to="{name: 'productView'}" class="button-card ">
          <font-awesome-icon icon="ship" class="fa-2x mx-auto mb-2" />
          Bateaux & Moteurs
        </router-link>
        <router-link :to="{name: 'locationView'}" class="button-card ">
          <font-awesome-icon icon="euro" class="fa-2x mx-auto mb-2" />
          Location
        </router-link>
        <router-link :to="{name: 'repairView'}" class="button-card">
          <font-awesome-icon icon="screwdriver-wrench" class="fa-2x mx-auto mb-2" />
          Réparations
        </router-link>
        <router-link :to="{name: 'ameliorationView'}" class="button-card">
          <font-awesome-icon icon="paint-brush" class="fa-2x mx-auto mb-2" />
          Modifications & Améliorations
        </router-link>
        <router-link :to="{name: 'winterizeView'}" class="button-card">
          <font-awesome-icon icon="warehouse" class="fa-2x mx-auto mb-2" />
          Hivernage
        </router-link>
      </div>

      <div class="flex flex-wrap justify-evenly">
        <span class="w-36 lg:w-60 logo" ><img src="../assets/img/marque/gemini.webp" alt="logo_gemini"></span>
        <span class="w-24 lg:w-40 grid content-center my-8 mx-4" ><img src="../assets/img/marque/tiger_marine.webp" alt="logo_tiger_marine"></span>
        <span class="w-24 lg:w-60 grid content-center my-8 mx-4" ><img src="../assets/img/marque/falcon.webp" alt="logo_falcon"></span>
        <span class="w-24 lg:w-60 grid content-center my-8 mx-4" ><img src="../assets/img/marque/suzuki_marine.webp" alt="logo_suzuki"></span>
        <span class="w-24 lg:w-60 grid content-center my-8 mx-4" ><img src="../assets/img/marque/black_manta.webp" alt="logo_black_manta"></span>
      </div>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',

  mounted() {
    window.addEventListener('scroll', this.zoomOnScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.zoomOnScroll);
  },
  methods:{
    zoomOnScroll(){
      var scroll = window.$(window).scrollTop();
      window.$(".zoom img").css({

        transform: 'translate3d(-50%, -'+(scroll/100)+'%, 0) scale('+(100 + scroll/5)/100+')',
        //"-webkit-filter": "blur(" + (scroll/200) + "px)",
        //filter: "blur(" + (scroll/200) + "px)"
      });
      if (scroll > 120){
        document.querySelector('.title').style.visibility = 'hidden'
        document.querySelector('.title').style.opacity = '0'
      }
      else{
        document.querySelector('.title').style.visibility = 'visible'
        document.querySelector('.title').style.opacity = '1'
      }
    }
  }
}
</script>

<style  scoped>

.main{
  min-height: 48vh;
}
.button-card{
  @apply grid content-center text-center w-full md:w-48 h-40 border-4 border-blue-100 rounded-xl
  mx-auto my-3 md:mx-3 hover:scale-105 duration-200 hover:shadow-lg hover:shadow-blue-100 text-blue-300 bg-white;
  z-index: 1000;
}
.title{
  text-shadow: 2px 1.5px #72b1f8;
  transition : all 0.3s ease ;
}
.logo{
  @apply grid content-center my-8 mx-4;
}
.main-img{
  height: 60vh;
  width: 100%;
  object-fit: cover;

  filter: brightness(109%) saturate(187%) contrast(30%);
  -webkit-filter: brightness(109%) saturate(187%) contrast(30%);
  -moz-filter: brightness(109%) saturate(187%) contrast(30%);

  animation-name: zoomin;
}

.zoom{
  overflow: hidden;
  height: 45vh;
}
.zoom img{
  position: fixed;
  top: 0;
  left: 50%;
  max-width: 200%;
  width: 100%;
  transform: translateX(-50%);
  /* Thanks  @bastian_fiessinger. Use when activating blur effect:
  will-change: -webkit-filter, filter, transform; */
}
@media (max-width: 667px) {
  h1 {
    font-size: 1.8rem;
    line-height: 2.6rem;
    text-align: left;
  }


  .zoom {
    overflow: hidden;
  }
}

</style>
