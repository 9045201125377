<template>
  <div class="main-grid grid min-h-screen">

    <NavBar />

    <div class="pt-20">
      <router-view v-slot="{ Component }">
        <transition name="route" mode="out-in">
          <component :is="Component"> </component>
        </transition>
      </router-view>
    </div>

    <FooterComponent />
  </div>

</template>

<style>
.main-grid{
  grid-template-rows: 1fr auto;
}
body{
  font-family: Poppins,sans-serif;
}
@media screen and (min-width: 1000px){
  /* route transitions */
  .route-enter-from {
    opacity : 0 ;
  }
  .route-enter-active {
    transition : all 0.3s ease-in ;
  }
  .route-leave-to {
    opacity : 0 ;
  }
  .route-leave-active {
    transition : all 0.1s ease-out ;
  }
}


@font-face {
  font-family: "CustomFont";
  src: url("./assets/fonts/New Aeonik Trials/AeonikTRIAL-Bold.otf");
}


.ltor {
  animation-duration: 1s;
  animation-name: l-to-r;
}

.rtol {
  animation-duration: 1s;
  animation-name: r-to-l;
}

@keyframes l-to-r {
  from {
    margin-left: -110%;
  }
  to {
    margin-left: 0;
  }
}

@keyframes r-to-l {
  from {
    margin-left: 110%;
  }
  to {
    margin-left: 0;
  }

}
@keyframes zoomin {
  from{
    transform: scale(0.7);
  }
  to{
    transform: scale(1);
  }
}
</style>

<script>
import NavBar from "@/layout/NavBar";
import FooterComponent from "@/layout/FooterComponent";
export default {
  components: {FooterComponent, NavBar}
}
</script>