<template>
  <div class="sine-wave -z-20 left-0">
    <svg class="svg-waves" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
      </defs>
      <g class="svg-waves__parallax">
        <use xlink:href="#gentle-wave" x="48" y="0"></use>
        <use xlink:href="#gentle-wave" x="48" y="3"></use>
        <use xlink:href="#gentle-wave" x="48" y="5"></use>
        <use xlink:href="#gentle-wave" x="48" y="7"></use>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "WaveComponent"
}
</script>

<style scoped>

/* Sine Wave Animation Effect */

.svg-waves {
  width: 100%;
  height: 180px;
}

@media (max-width: 767px) {
  .svg-waves {
    height: 80px;
  }
}

.svg-waves__parallax > use {
  -webkit-animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.svg-waves__parallax > use:nth-child(1) {
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
  -webkit-animation-duration: 7s;
  animation-duration: 7s;
  fill: rgba(138, 223, 241, 0.7);

}

.svg-waves__parallax > use:nth-child(2) {
  -webkit-animation-delay: -3s;
  animation-delay: -3s;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  fill: rgba(255, 255, 255, 0.5);
}

.svg-waves__parallax > use:nth-child(3) {
  -webkit-animation-delay: -4s;
  animation-delay: -4s;
  -webkit-animation-duration: 13s;
  animation-duration: 13s;
  fill: rgba(255, 255, 255, 0.3);
}

.svg-waves__parallax > use:nth-child(4) {
  -webkit-animation-delay: -5s;
  animation-delay: -5s;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  fill: skyblue;
}

@-webkit-keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

</style>