import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/index.css'

import jQuery from "jquery";

import { library } from "@fortawesome/fontawesome-svg-core";
import {faPhone, faLocationPin, faEnvelope, faShip, faScrewdriverWrench, faPaintBrush, faWarehouse, faMobile, faEuro} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const $ = jQuery;
window.$ = $;

library.add(faPhone, faLocationPin, faEnvelope, faShip, faScrewdriverWrench, faPaintBrush, faWarehouse, faMobile, faEuro);


createApp(App)
    .component("font-awesome-icon", FontAwesomeIcon)
    .use(store)
    .use(router)
    .mount('#app')
