<template>
  <div class="navbar-area h-20 xl:px-10 " :class="{ 'hidden-navbar': !showNavbar && navIsOpen, 'visible-navbar' : showNavbar && navIsOpen }">
    <div class="container-custom">
      <nav class="site-navbar text-sm ">
        <router-link :to="{name: 'home'}" ><img class="w-32" src="../assets/logo.webp" alt="logo"></router-link>
        <!-- site menu/nav -->
        <ul>
          <li >
            <router-link data-replace="Menu"  @click="navLinkClick" :to="{name: 'home'}">Menu</router-link>
          </li>
          <li >
            <router-link @click="navLinkClick" :to="{name: 'productView'}">Bateaux et Moteurs</router-link>
          </li>
          <li >
            <router-link @click="navLinkClick" :to="{name: 'locationView'}">Location</router-link>
          </li>
          <li >
            <router-link @click="navLinkClick" :to="{name: 'repairView'}">Réparations</router-link>
          </li>
          <li >
            <router-link @click="navLinkClick" :to="{name: 'ameliorationView'}">Modifications & Améliorations</router-link>
          </li>
          <li >
            <router-link @click="navLinkClick" :to="{name: 'winterizeView'}">Hivernage</router-link>
          </li>

        </ul>
        <!-- nav-toggler for mobile version only -->
        <button class="nav-toggler mr-6 mt-3" v-on:click="togglerClick">
          <span></span>
        </button>
      </nav>
    </div>
  </div>

</template>

<script>
import '../assets/css/navbar.css'

export default {
  name: "NavBar",
  data() {
    return {
      navToggler: null,
      navMenu: null,
      OFFSET: 30,
      showNavbar: true,
      lastScrollPosition: 0,
      scrollValue: 0,
      navIsOpen: true,
    }
  },
  mounted() {
    this.navToggler = document.querySelector('.nav-toggler');
    this.navMenu = document.querySelector('.site-navbar ul');
    this.lastScrollPosition = document.documentElement.scrollTop;

    window.addEventListener('scroll', this.onScroll);

  },
  methods:{
    togglerClick() {
      this.navToggler.classList.toggle('toggler-open');
      this.navMenu.classList.toggle('open');
      document.body.classList.toggle('stop-scroll')
      this.navIsOpen = !this.navIsOpen;
    },
    onScroll () {
      if (document.documentElement.scrollTop < 0) {
        return;
      }
      if (Math.abs(document.documentElement.scrollTop - this.lastScrollPosition) < this.OFFSET) {
        return;
      }
      this.showNavbar = document.documentElement.scrollTop < this.lastScrollPosition;
      this.lastScrollPosition = document.documentElement.scrollTop;
    },
    navLinkClick() {
      if(this.navMenu.classList.contains('open')) {
        this.navToggler.click();
      }
    },
  }
}
</script>

<style scoped>

</style>