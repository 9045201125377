<template>
  <div class="md:p-10 p-4 ">
    <h1 class="md:text-4xl text-2xl font-bold text-center">Location de semi-rigide</h1>
    <div class="m-4 relative">
      <img class="rtol-custom mt-10 lg:-mb-60 lg:ml-[45%] rounded z-30" src="../assets/img/location/image000012.webp" alt="">
      <div class="lg:absolute lg:-mt-20 xl:-mt-32 2xl:-mt-52 lg:ml-20 text-center">
        <span class="font-[rota] text-lg sm:text-2xl">En savoir plus sur :</span>
        <a target="_blank" class="font-[phoenix] text-[25px] sm:text-[35px] py-1 px-4 text-blue-400 hover:text-blue-800
                  hover:bg-white duration-300" href="https://www.blue-mare.com/">Blue Mare</a>
      </div>
      <img class="ltor img2 rounded z-50 mt-5 lg:mt-0 z-30" src="../assets/img/location/image000009.webp" alt="">
    </div>

  </div>
</template>

<script>
export default {
  name: "LocationView"
}
</script>

<style scoped>

img{
  @apply lg:w-3/6 border-8 border-blue-100 w-full;
}

@font-face {
  font-family: "phoenix";
  src: url("../assets/fonts/Phoenix-Island/Phoenix Island.otf");
}

@font-face {
  font-family: "rota";
  src: url("../assets/fonts/Rota - FREE/Rota-Black.otf");
}

.rtol-custom {
  animation-duration: 1s;
  animation-name: r-to-l-custom;
}


@keyframes r-to-l-custom {
  from {
    margin-left: 110%;
  }
  to {
    margin-left: fill;
  }
}

@keyframes r-to-l-custom-mobile {
  from {
    margin-left: 110%;
  }
  to {
    margin-left: fill;
  }
}

@media screen and (max-width: 1024px){
  .rtol-custom {
    animation-name: r-to-l-custom-mobile;
  }

}
</style>