<template>
  <div class="md:p-10 p-4 ">
    <h1 class="md:text-4xl text-2xl font-bold text-center">Stockage sous cocooning forfait tout inclus</h1>
    <div class="m-4">
      <img class="border-4 border-blue-200 rounded-lg" src="../assets/img/stockage/stockage_bateaux.webp" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "WinterizeView"
}
</script>

<style scoped>

</style>