<template>
  <div >
    <div class="diag-bg h-1/2 absolute w-full -z-50 top-1/3"></div>

    <div class="md:p-10 p-4 ">
      <h1 class="md:text-4xl text-2xl font-bold text-center">Sellerie, soudure inox & alu</h1>

      <div class="mt-16 md:flex justify-evenly">

        <div class="p-2 md:p-5 m-3 rounded-2xl border border-blue-200 bg-white text-gray-700 h-min">
          <p class="text-justify p-3 text-gray-700 text-md md:text-lg ">Nous étudions et réalisons toutes vos demandes (vivier, guindeau électrique, propulseur,  etc...)</p>
        </div>

        <swiper
            :effect="'cards'"
            :grabCursor="true"
            :modules="modules"
            :navigation="true"
            class="mySwiper ">
          <swiper-slide>
            <img src="../assets/img/amelioration/image000002.webp" alt="">
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/img/amelioration/image000010.webp" alt="">
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/img/amelioration/image000003.webp" alt="">
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/img/amelioration/image000005.webp" alt="">
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/img/amelioration/image000006.webp" alt="">
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/img/amelioration/image000011.webp" alt="">
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/img/amelioration/image000013.webp" alt="">
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/img/amelioration/revetement_techpro2a.webp" alt="">
          </swiper-slide>
        </swiper>
      </div>
    </div>

  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/effect-cards";
import "swiper/css/navigation";


// import required modules
import { EffectCards, Navigation } from "swiper";


export default {
  name: "ServiceView",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      success: false
    }
  },
  setup() {
    return {
      modules: [EffectCards, Navigation],
    };
  },
  methods:{

  }
}
</script>

<style scoped>
.diag-bg {
  background-image: linear-gradient(45deg, #0334b1, #3cffff);
  transform: skewY(20deg);
}
.mySwiper {
  width: 400px;
  height: 700px;
  margin: 0 40px;
}

.mySwiper .swiper-slide {

  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}

.mySwiper img{
  width: 400px;
  height: 700px;
  object-fit: cover;
  object-position: bottom;
  bottom: 0;
}


@media screen and (max-width: 768px){

  .mySwiper img{
    width: 200px;
    height: 350px;

  }
  .mySwiper {
    width: 200px;
    height: 350px;
    margin: 0 auto;
  }
}

</style>