<template>
  <footer class="h-46 border-t-2 border-[lightskyblue] bg-white">
    <div class="py-10 px-4 md:px-20 text-gray-500 md:flex md:space-x-12 space-y-12 md:space-y-0 ">
      <router-link to="/"><img src="../assets/logo.webp" alt="logo" class="w-36"></router-link>
      <div class="grid mt-5 pl-5 md:pl-32">
        <h2 class="text-lg text-neutral-800 font-bold">Contact</h2>
        <hr class="w-24 border-2 rounded">
        <a target="_blank"
           href="https://www.google.fr/maps/search/zone+d'activit%C3%A9s+de+Musella,+Bonifacio/@41.4036973,9.1986119,17z/data=!3m1!4b1">
          <font-awesome-icon icon="location-pin" />
          ZA de musella 20169 Bonifacio
        </a>
        <a href="tel:0420204617" ><font-awesome-icon icon="phone" /> 04 20 20 46 17</a>
        <a href="tel:0633808095" ><font-awesome-icon icon="mobile" /> 06 33 80 80 95 </a>
        <a href="mailto:techpro2a@cegetel.net" ><font-awesome-icon icon="envelope" /> techpro2a@cegetel.net</a>
      </div>
    </div>
    <WaveComponent />

  </footer>

</template>

<script>
import WaveComponent from "@/components/WaveComponent";
export default {
  name: "FooterComponent",
  components: {WaveComponent}
}
</script>

<style scoped>
a{
  @apply mt-2 hover:text-orange-300 hover:scale-105 duration-200;
}
</style>